import React from 'react';


const ServiceContainer = ({children, title, description}) => (
  <div className="container">
    <div className="text-center main-title-block main_price"><h2 className="with-underline about-main-title">{title}</h2></div>
    <div className="main-content main-content__black">
      <div>{description}</div>
      {children}
    </div>
  </div>
);

export default ServiceContainer;