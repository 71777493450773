import React, {useEffect, useState} from 'react';
import {gapi} from "gapi-script"

const AdditionalTable = () => {
  const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

  const SPREADSHEET_ID = "1bxJsPl_675kMEPzBG_qFZQ8GaToMesumrrKDT9Tngv8";

  const API_KEY = "AIzaSyAoRRN-VM8H5qgjZGbIQvQEmnKUGIrwrJY"

  const [goods, setGoods] = useState([])

  /**Получение товаров из гугл таблицы*/
  const getGoods = () => {
    gapi.load("client", () => {
      gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: "streamy"
      }).then(() => {
        return gapi.client.sheets.spreadsheets.values.get({
          spreadsheetId: SPREADSHEET_ID,
          range: 'Banya_Tovary!A1:B1000', // for example: List 1!A1:B6
        }).then((res) => {
          setGoods(res.result.values.filter((el) => (el.length)))
        })
      })
    })
  }

  useEffect(() => {
    getGoods();
  }, [])

  const goodsRows = goods.slice(1);

  return (

    <table className="table table-striped cost-table">
      <tbody>
      {
        goodsRows.map((row, index) => (
          <tr key={`row${index}`} className="table-flex-row">
            <td className="table-flex-cell">{row[0]}</td>
            <th className="table-flex-cell table-flex-cell_left">{row[1]} &#8381;</th>
          </tr>
        ))
      }
      </tbody>
    </table>

  )
};

export default AdditionalTable;