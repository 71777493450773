import React from 'react';
import Layout from "../components/layout";
import banner from "../images/Main/bani-winter.webp";
import {Link} from "gatsby";
import CostTable from "../components/cost-table";

const Price = () => (
  <Layout
    metaContent="Стоимость услуг банного комплекса в Затоне"
    title="Затонские Бани - Прайс"
    keywords="стоимость бани по серому затон, стоимость бани по черному затон, цена чана в затонских банях"
  >
    <div className="banner-container">
      <div className="site-Banner">
        <img src={banner} alt="Баня по-черному" title="Баня по-черному" className="banner-img" />
        <div className="Banner-details">
          <h1>Затонские Бани</h1>
          <span className="banner-adress"><i
            className="fa fa-map-marker-alt" />{" "}п. Затон ул.Водников 1е</span><br />
          {/*{*/}
          {/*  currentUser ? (*/}
          <Link to="/order/">Заказать</Link>
          {/*) : (*/}
          {/*  <a href="http://localhost:3000/">Заказать</a>*/}
          {/*)*/}
          {/*}*/}
        </div>
      </div>
      <CostTable/>
      <div className="order-btn-wrap">
        {/*  {*/}
        {/*    currentUser ? (*/}
        <Link to="/order/">Заказать</Link>
        {/*) : (*/}
        {/*  <a href="http://localhost:3000/">Заказать</a>*/}
        {/*)*/}
        {/*}*/}
      </div>
    </div>
  </Layout>
);

export default Price;