import React, {useState, useEffect} from 'react';
import ServiceContainer from "../components/service-container";
import AdditionalTable from "../components/additional-table"
import {gapi} from "gapi-script"

const CostTable = () => {
  const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

  const SPREADSHEET_ID = "1bxJsPl_675kMEPzBG_qFZQ8GaToMesumrrKDT9Tngv8";

  const API_KEY = "AIzaSyAoRRN-VM8H5qgjZGbIQvQEmnKUGIrwrJY"

  const [services, setServices] = useState([])

  /**Получение услуг из гугл таблицы*/
  const getServices = () => {
    // setSpinner(true)
    try {
      gapi.load("client", () => {
        gapi.client.init({
          apiKey: API_KEY,
          discoveryDocs: DISCOVERY_DOCS,
          plugin_name: "streamy"
        }).then(() => {

          return gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: SPREADSHEET_ID,
            range: 'Banya_Uslugi!A1:C1000', // for example: List 1!A1:B6
          }).then((res) => {
            setServices(res.result.values)
            // setSpinner(false)
          })
        })
      })
    } catch (err) {
      return console.log(err.message);
    }
  }

  useEffect(() => {
    getServices();
  }, [])

  const servicesRows = services.slice(1).flat();

  return (
    <ServiceContainer title='Прайс товаров и услуг банного комплекса'>
      <h2 className="table-title">Услуги</h2>
      <table className="table table-striped cost-table">
        <thead>
        <tr>
          <th scope="col">Время посещения</th>
          <th scope="col">Баня по-черному</th>
          <th scope="col">Баня по-серому</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Понедельник - Четверг до 17:00</td>
          <th>{servicesRows[11]} &#8381;/час</th>
          <th>{servicesRows[2]}&#8381;/час</th>
        </tr>
        <tr>
          <td>Понедельник - Четверг после 17:00</td>
          <th>{servicesRows[14]} &#8381;/час</th>
          <th>{servicesRows[5]} &#8381;/час</th>
        </tr>
        <tr>
          <td>Пятница - Воскресенье</td>
          <th>{servicesRows[17]} &#8381;/час</th>
          <th>{servicesRows[8]} &#8381;/час</th>
        </tr>
        </tbody>
      </table>
      <h2 className="table-title">Товары</h2>
      <AdditionalTable/>
      <h2 className="table-title">Дополнительно</h2>
      <ol className="price-list">
        <li className="price-item">
          Чан первые 2 часа - {servicesRows[20]} &#8381;, каждый последующий час 1000 &#8381;
        </li>
        {/*<li className="price-item">*/}
        {/*  Парение - 30 мин на одного человека - {servicesRows[23]} &#8381;*/}
        {/*</li>*/}
        <li className="price-item">
          Максимальное колличество человек - 10
        </li>
        <li className="price-item">
          В стоимость заказа входит до 6 человек
        </li>
        <li className="price-item">
          За каждого последующего человека после 6-го - {servicesRows[26]}₽/час
        </li>
        <li className="price-item">
          Минимальное время заказа в будни - 2 часа
        </li>
        <li className="price-item">
          Минимальное время заказа в выходные - 3 часа
        </li>
      </ol>
    </ServiceContainer>
  )
};

export default CostTable;